import Routing from "./Routing/Routing";
import firebase from "./helpers/firebase";
import React, { useEffect } from "react";
import Zendesk from "react-zendesk";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import axios from "axios";

const setting = {
  color: {
    theme: "#8A2BE2",
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } },
    ],
  },
};

function App() {
  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const msg = firebase.messaging();
      msg
        .requestPermission()
        .then(() => {
          return msg.getToken();
        })
        .then((data) => {
          localStorage.setItem("dToken1", data);
        })
        .catch((e) => {
          console.log("errorrrr", e);
        });
    } else {
      console.log("Not supported");
    }
  }, []);
  useEffect(async () => {
    // await navigator.geolocation.getCurrentPosition((position) => {
    //   fetch(
    //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GMAP_API}`,
    //     {
    //       method: "GET",
    //     }
    //   )
    //     .then((res) => res.json())
    //     .then((response) => {
    //       localStorage.setItem(
    //         "userCurrentLocation",
    //         response.results.find((x) => x.types[0] == "country")
    //           .formatted_address
    //       );
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // });
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/user/get_ip_info.php`)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem(
            "userCurrentLocation",
            res.data.data.ip_base_country_code
          );
        }
      });
  }, []);
  return (
    <div>
      {/* <Zendesk
        defer
        zendeskKey={"673870e5-9d61-4c2a-935c-a64ead66d356"}
        {...setting}
        onLoaded={() => console.log("is loaded zendesk")}
      /> */}
      <Routing />
    </div>
  );
}

export default App;
