import React from "react";
import "./PersonalDetails.css";
import { getPersonalLogin } from "../../actions/personalloginAction";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import axios from "axios";
import { countries } from "country-data";
import Modal from "react-modal";
import IntlTelInput from "react-intl-tel-input";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

function PersonalDetail(props) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [numberChangeModal, setNumberChangeModal] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  let param = useLocation().search;
  param = param.split("=");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    let formData = new FormData(); //formdata object
    formData.append("file", data.fileData);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("num", data.num);
    formData.append("num_code", data.num_code);
    formData.append("dob", data.dob);
    formData.append("gender", data.gender);
    formData.append("marital_stat", data.marital_stat);
    formData.append("country", data.country);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("nationality", data.nationality);
    for (const l of data.lang) {
      formData.append("lang[]", l.id);
    }
    fetch(`${process.env.REACT_APP_API_END_POINT}/user/add_profile.php`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        auth_id: localStorage.getItem("auth_id"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status != 200) {
          alert(response.message);
          return;
        }
        if (param[1] === "editProfile") {
          window.location = "/profile";
        } else {
          window.location = "/professional?type=add";
        }
      })
      .catch((error) => {});
  };
  const watchAllFields = watch();
  useEffect(() => {
    props
      .getPersonalLogin(localStorage.getItem("auth_id"))
      .then(async (res) => {
        setFormData((prev) => (prev = res));
        let { stored_values } = res;
        let resetData = {
          file: stored_values.dp,
          fileData: stored_values.dp,
          first_name: stored_values.first_name,
          last_name: stored_values.last_name,
          email: stored_values.email,
          num: stored_values.num,
          num_code: stored_values.num_code,
          country: stored_values.country.id,
          gender: stored_values.gender,
          nationality: stored_values.nationality.id,
          state: stored_values.state.id,
          city: stored_values.city.id,
          marital_stat: stored_values.marital_status.id,
          dob: stored_values.dob,
          lang: stored_values.languages,
        };
        await getAllStatesData(resetData);
        await reset(resetData);
        await setSelectedCountryCode(
          (prev) =>
            (prev = countries.all
              .find(
                (x) =>
                  x.countryCallingCodes[0] ==
                  props?.personalloginReducer?.personal?.stored_values?.num_code
              )
              ?.alpha2?.toLowerCase())
        );
        await setLoader(false);
      });
  }, []);
  const getAllStatesData = async (storeData) => {
    await axios
      .get(`${process.env.REACT_APP_API_END_POINT}/user/get_country_list.php`)
      .then(async (res) => {
        await setFormData((prev) => ({
          ...prev,
          countries: res.data.data.country,
        }));
        if (param[1] === "editProfile") {
          await axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/user/fetch_state_list.php`,
              {
                country_id: storeData.country,
              }
            )
            .then(async (res) => {
              await setFormData((prev) => ({
                ...prev,
                states: res.data.data.state_list,
              }));
              await axios
                .post(
                  `${process.env.REACT_APP_API_END_POINT}/user/fetch_city_list.php`,
                  {
                    country_id: storeData.country,
                    state_id: storeData.state,
                  }
                )
                .then(async (res) => {
                  await setFormData((prev) => ({
                    ...prev,
                    cities: res.data.data.city_list,
                  }));
                });
            });
        }
      });
  };
  const handleCountry = (e) => {
    if (e.target.value) {
      axios
        .post(
          `${process.env.REACT_APP_API_END_POINT}/user/fetch_state_list.php`,
          {
            country_id: e.target.value,
          }
        )
        .then((res) => {
          setFormData((prev) => ({
            ...prev,
            states: res.data.data.state_list,
          }));
        });
    }
  };
  const handleState = (e) => {
    if (e.target.value) {
      axios
        .post(
          `${process.env.REACT_APP_API_END_POINT}/user/fetch_city_list.php`,
          {
            country_id: watchAllFields.country,
            state_id: e.target.value,
          }
        )
        .then((res) => {
          setFormData((prev) => ({
            ...prev,
            cities: res.data.data.city_list,
          }));
        });
    }
  };
  const newNumberVerify = () => {
    if (!watchAllFields.num) {
      alert("Enter Mobile Number");
      return;
    }
    fetch(
      `${process.env.REACT_APP_API_END_POINT}/user/send_change_number_otp.php`,
      {
        method: "POST",
        body: JSON.stringify({
          number: watchAllFields.num,
          num_code: watchAllFields.num_code,
        }),
        headers: {
          auth_id: localStorage.getItem("auth_id"),
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        localStorage.setItem(
          "newNumber",
          JSON.stringify({
            number: watchAllFields.num,
            code: watchAllFields.num_code,
          })
        );
        window.location = "/otp?type=numberChanged";
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  if (loader) {
    return <FullPageLoader />;
  }
  return (
    <>
      <div className="container mt-5">
        <h1 className="text-center per-det-head">Personal Details</h1>
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <form
              className="shadow p-5 per-det-main"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row d-flex justify-content-center">
                <div className="col-12">
                  <input
                    type="file"
                    id="image"
                    className="inputs"
                    accept="image/png, image/gif, image/jpeg"
                    {...register("file", {
                      onChange: (e) => {
                        if (e.target.files[0]) {
                          setValue("fileData", e.target.files[0]);
                          const reader = new FileReader();
                          reader.addEventListener("load", () => {
                            setValue("file", reader.result);
                          });
                          reader.readAsDataURL(e.target.files[0]);
                        }
                      },
                    })}
                  />
                  {watchAllFields?.file ? (
                    <img
                      className="p-3 rounded"
                      src={watchAllFields?.file}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "200px",
                      }}
                    />
                  ) : (
                    <i className="fas fa-user fa-3x border rounded p-4"></i>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    First name <span className="error-message">*</span>
                  </label>
                  <input
                    id="inp"
                    type="text"
                    className="form-control per-det-input"
                    placeholder="Type your first name"
                    {...register("first_name", {
                      required: true,
                      maxLength: 20,
                    })}
                  />
                  {errors.first_name?.type === "required" && (
                    <p className="error-message">First name is required</p>
                  )}
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    Last name <span className="error-message">*</span>
                  </label>
                  <input
                    id="inp"
                    type="text"
                    className="form-control per-det-input"
                    placeholder="Type your first name"
                    {...register("last_name", {
                      required: true,
                      maxLength: 20,
                    })}
                  />
                  {errors.last_name?.type === "required" && (
                    <p className="error-message">Last name is required</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    Email Address <span className="error-message">*</span>
                  </label>
                  <input
                    id="inp"
                    type="text"
                    className="form-control per-det-input"
                    placeholder="Type your first name"
                    {...register("email", {
                      required: true,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <p className="error-message">Email is required</p>
                  )}
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    Phone number <span className="error-message">*</span>
                  </label>
                  <div className="row">
                    <div className="col-3 d-flex pr-0">
                      <select
                        style={{
                          border: "1px solid #c8c8c8",
                          borderRadius: "5px",
                          color: "#000",
                          width: "100%",
                        }}
                        {...register("num_code", {
                          required: true,
                        })}
                        disabled={param[1] === "editProfile" ? true : false}
                      >
                        <option key="">Select Code</option>
                        {countries.all.map((exp, i) =>
                          exp.countryCallingCodes.length ? (
                            <option key={i} value={exp.countryCallingCodes[0]}>
                              {exp.countryCallingCodes[0]}
                            </option>
                          ) : null
                        )}
                      </select>
                    </div>
                    <div className="d-flex col-9">
                      <input
                        id="inp"
                        type="text"
                        className="form-control per-det-input"
                        placeholder="Type your first name"
                        onClick={() => {
                          if (param[1] === "editProfile") {
                            setNumberChangeModal(true);
                          }
                        }}
                        readOnly={param[1] === "editProfile" ? true : false}
                        {...register("num", {
                          required: true,
                        })}
                      />
                    </div>
                  </div>

                  {errors.num?.type === "required" && (
                    <p className="error-message">Phone is required</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    Country <span className="error-message">*</span>
                  </label>
                  <select
                    className="form-control per-det-input"
                    {...register("country", {
                      required: true,
                      onChange: (e) => handleCountry(e),
                    })}
                  >
                    <option value="">Select Your Country</option>
                    {formData?.countries?.map((x, i) => (
                      <option key={i} value={x.country_id}>
                        {x.country_name}
                      </option>
                    ))}
                  </select>
                  {errors.country?.type === "required" && (
                    <p className="error-message">Country is required</p>
                  )}
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    Gender <span className="error-message">*</span>
                  </label>
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="male"
                      value={1}
                      name="gender"
                      checked={watchAllFields?.gender == 1}
                      {...register("gender", {
                        required: true,
                      })}
                    />
                    <label
                      className="form-check-label per-det-input"
                      htmlFor="male"
                    >
                      Male
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="female"
                      value={0}
                      name="gender"
                      checked={watchAllFields?.gender == 0}
                      {...register("gender", {
                        required: true,
                      })}
                    />

                    <label
                      className="form-check-label per-det-input"
                      htmlFor="female"
                    >
                      Female
                    </label>
                  </div>
                  {errors.gender?.type === "required" && (
                    <p className="error-message">Gender is required</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    State / Province <span className="error-message">*</span>
                  </label>
                  <select
                    className="form-control per-det-input"
                    {...register("state", {
                      required: true,
                      onChange: (e) => handleState(e),
                    })}
                  >
                    <option value="">Select Your State</option>
                    {formData?.states?.map((x, i) => (
                      <option key={i} value={x.state_id}>
                        {x.state_name}
                      </option>
                    ))}
                  </select>
                  {errors.state?.type === "required" && (
                    <p className="error-message">State is required</p>
                  )}
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    Nationality <span className="error-message">*</span>
                  </label>
                  <select
                    className="form-control per-det-input"
                    {...register("nationality", {
                      required: true,
                    })}
                  >
                    <option value="">Select Your Nationality</option>
                    {formData?.nationality?.map((x, i) => (
                      <option key={i} value={x.id}>
                        {x.nat_name}
                      </option>
                    ))}
                  </select>
                  {errors.nationality?.type === "required" && (
                    <p className="error-message">Nationality is required</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    City <span className="error-message">*</span>
                  </label>
                  <select
                    className="form-control per-det-input"
                    {...register("city", {
                      required: true,
                    })}
                  >
                    <option value="">Select Your City</option>
                    {formData?.cities?.map((x, i) => (
                      <option key={i} value={x.city_id}>
                        {x.city_name}
                      </option>
                    ))}
                  </select>
                  {errors.city?.type === "required" && (
                    <p className="error-message">City is required</p>
                  )}
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    Marital Status <span className="error-message">*</span>
                  </label>
                  <select
                    className="form-control per-det-input"
                    {...register("marital_stat")}
                  >
                    <option value="">Select Your Nationality</option>
                    {formData?.marital_statuses?.map((x, i) => (
                      <option key={i} value={x.id}>
                        {x.m_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    Date of Birth <span className="error-message">*</span>
                  </label>
                  <input
                    id="inp"
                    type="date"
                    className="form-control per-det-input"
                    {...register("dob", {
                      required: true,
                    })}
                  />
                  {errors.dob?.type === "required" && (
                    <p className="error-message">Date of Birth is required</p>
                  )}
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                  <label className="per-det-label mt-3">
                    Languages <span className="error-message">*</span>
                  </label>
                  <select
                    className="form-control per-det-input"
                    {...register("language", {
                      onChange: (e) => {
                        let prev = watchAllFields?.lang;
                        let selected = formData.languages.find(
                          (x) => x.id == e.target.value
                        );
                        setValue("lang", [...prev, selected]);
                      },
                    })}
                  >
                    <option value="">Select</option>
                    {formData?.languages?.map((x, i) => (
                      <option key={i} value={x.id}>
                        {x.label}
                      </option>
                    ))}
                  </select>
                  {errors?.lang?.type === "required" && (
                    <p className="error-message">Languages is required</p>
                  )}
                  {watchAllFields?.lang?.map((x, i) => (
                    <button
                      key={i}
                      onClick={() => {
                        let filteredData = watchAllFields?.lang.filter(
                          (y) => y.id != x.id
                        );

                        setValue("lang", filteredData);
                      }}
                      className="btn btn-light mt-2 btn-color-key mx-1"
                      id="btnn"
                      type="button"
                      style={{ fontSize: "12px" }}
                    >
                      <span className="btn-label">
                        <i className="far fa-trash pr-1"></i>
                      </span>

                      {x.label}
                    </button>
                  ))}
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 d-flex justify-content-center">
                  <input
                    type="submit"
                    value="Save Changes"
                    id="persDet"
                    className="btn btn-warning per-det-btn"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        isOpen={numberChangeModal}
        onRequestClose={() => setNumberChangeModal(false)}
        className="shadow"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
          content: {
            background: "#fff",
            border: "0",
            position: "absolute",
            zIndex: 1000,
            margin: "0 auto",
            width: "525px",
            minHeight: "230px",
            height: "230px",
            top: "220px",
            left: "0",
            right: "0",
            bottom: "50px",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "50px 0px",
            borderRadius: "20px",
          },
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <label className="input-label">Phone Number</label>
            </div>
            <div className="col-12">
              <IntlTelInput
                preferredCountries={["pk"]}
                inputClassName="form-control per-det-input"
                value={watchAllFields.num}
                defaultCountry={selectedCountryCode}
                onPhoneNumberChange={(status, phoneNumber, country) => {
                  setValue("num_code", country.dialCode);
                  setValue("num", phoneNumber);
                }}
              />
            </div>
            <div className="col-6">
              <button
                className="btn btn-warning per-det-btn w-100 mt-3"
                onClick={() => newNumberVerify()}
              >
                Change
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-warning per-det-btn w-100 mt-3"
                onClick={() => setNumberChangeModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  personalloginReducer: state.personalloginReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPersonalLogin: (userId) => dispatch(getPersonalLogin(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetail);
