import React from "react";
import "./ProfessionalDetails.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getProfessionalLogin,
  createProfessionalLogin,
} from "../../actions/professionalloginAction";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { countries } from "country-data";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

function ProfessionalDetailsLogin(props) {
  const [formData, setFormData] = useState({});
  let param = useLocation().search;
  param = param.split("=");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    props.createProfessionalLogin(
      localStorage.getItem("auth_id"),
      data,
      param[1] === "edit" ? "/profile" : "/qualification?type=add"
    );
  };
  const watchAllFields = watch();
  useEffect(() => {
    props
      .getProfessionalLogin(localStorage.getItem("auth_id"))
      .then(async (res) => {
        setFormData((prev) => (prev = res));
        let { stored_values } = res;
        let resetData = {
          exp_level: stored_values.exp_level?.id,
          industry: stored_values.industry?.cat_id,
          functional_area: stored_values.functional_area?.id,
          wrk_lvl: stored_values.wrk_lvl?.id,
          currency: stored_values.currency,
          salary: stored_values.salary,
          availability: stored_values.availability?.id,
          employed_status: stored_values.employed_status,
          curr_title: stored_values.curr_title,
          curr_rec: stored_values.curr_rec,
          employer_city: stored_values.employer_city?.id,
          working_from: stored_values.working_from,
        };
        await reset(resetData);
      });
  }, []);
  return (
    <div className="container mt-5">
      {/* Row 1 for Heading Starts */}
      <div className="row mt-5">
        <div className="col-md-2"></div>
        <h1 className="col-md-8 d-flex justify-content-center prof-det-head">
          Professional Details
        </h1>
        <div className="col-md-2"></div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-1"></div>
          {/* Main Background of Input starts */}
          <div className="col-md-10 shadow p-5 prof-det-main">
            {/* Inputs Row 1 Starts */}
            <div className="row">
              <div className="col-md-5">
                <label className="label-color-prof mt-3">
                  Total Work Experience
                </label>
                <select
                  className="form-control input-color-prof"
                  {...register("exp_level", {
                    required: true,
                  })}
                >
                  <option value="">--Select--</option>
                  {formData?.experience_level?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.exp_name}
                    </option>
                  ))}
                </select>
                {errors.exp_level?.type === "required" && (
                  <p className="error-message">Experience is required</p>
                )}
              </div>
              {/* Radio here start */}
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <label className="label-color-prof mt-3">
                  Current Designation or Title (For recruiters to find you)
                </label>
                <input
                  className="form-control input-color-prof"
                  {...register("curr_title", {
                    required: true,
                  })}
                  placeholder="Enter your current designation or title"
                />
                {errors.curr_title?.type === "required" && (
                  <p className="error-message">Designation is required</p>
                )}
              </div>

              {/* Radio here end */}
            </div>
            {/* Inputs Row 1 Ends */}

            {/* Inputs Row 2 Starts */}
            <div className="row">
              <div className="col-md-5">
                <label className="label-color-prof mt-3">
                  Current Industry
                </label>
                <select
                  className="form-control input-color-prof"
                  {...register("industry", {
                    required: true,
                  })}
                >
                  <option value="">--Select--</option>
                  {formData?.industry?.map((x, i) => (
                    <option value={x.cat_id} key={i}>
                      {x.cat_name}
                    </option>
                  ))}
                </select>
                {errors.industry?.type === "required" && (
                  <p className="error-message">Industry is required</p>
                )}
              </div>
              {/* herreeeeee */}
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <label className="label-color-prof mt-3">
                  Currently Working
                </label>
                <br />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="yes"
                    value={1}
                    name="employed_status"
                    checked={watchAllFields?.employed_status == 1}
                    {...register("employed_status", {
                      required: true,
                    })}
                  />
                  <label
                    className="form-check-label per-det-input"
                    htmlFor="yes"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="no"
                    value={0}
                    name="employed_status"
                    checked={watchAllFields?.employed_status == 0}
                    {...register("employed_status", {
                      required: true,
                    })}
                  />

                  <label
                    className="form-check-label per-det-input"
                    htmlFor="no"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            {/* Inputs Row 2 Ends */}

            {/* Inputs Row 3 Starts */}
            <div className="row">
              <div className="col-md-5">
                <label className="label-color-prof mt-3">
                  Current Career level
                </label>
                <select
                  className="form-control input-color-prof"
                  {...register("functional_area", {
                    required: true,
                  })}
                >
                  <option value="">--Select--</option>
                  {formData?.functional_area?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.func_name}
                    </option>
                  ))}
                </select>
                {errors.functional_area?.type === "required" && (
                  <p className="error-message">
                    Current Career level is required
                  </p>
                )}
              </div>

              <div className="col-md-2"></div>
              {watchAllFields.employed_status != 0 ? (
                <div className="col-md-5">
                  <label className="label-color-prof mt-3">
                    Current Employer Name
                  </label>
                  <input
                    className="form-control input-color-prof"
                    {...register("curr_rec", {
                      required: true,
                    })}
                    placeholder="Enter your current employer's name"
                  />
                  {errors.curr_rec?.type === "required" && (
                    <p className="error-message">
                      Current Employer Name is required
                    </p>
                  )}
                </div>
              ) : null}
            </div>
            {/* Inputs Row 3 Ends */}

            {/* Inputs Row 4 Starts */}
            <div className="row">
              <div className="col-md-5">
                <label className="label-color-prof mt-3">
                  Current Job Level
                </label>
                <select
                  className="form-control input-color-prof"
                  {...register("wrk_lvl", {
                    required: true,
                  })}
                >
                  <option value="">--Select--</option>
                  {formData?.work_level?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.work_level}
                    </option>
                  ))}
                </select>
                {errors.wrk_lvl?.type === "required" && (
                  <p className="error-message">Current Job Level is required</p>
                )}
              </div>

              <div className="col-md-2"></div>
              {watchAllFields.employed_status == 1 ? (
                <div className="col-md-5">
                  <label className="label-color-prof mt-3">
                    Employer's Country
                  </label>
                  <select
                    className="form-control input-color-prof"
                    {...register("employer_city", {
                      required: true,
                    })}
                  >
                    <option value="">--Select--</option>
                    {formData?.employer_city?.map((x, i) => (
                      <option value={x.id} key={i}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                  {errors.employer_city?.type === "required" && (
                    <p className="error-message">Country is required</p>
                  )}
                </div>
              ) : null}
            </div>

            <div className="row">
              <div className="col-md-5">
                <label className="label-color-prof mt-3">Currency Type</label>
                <select
                  className="form-control input-color-prof"
                  {...register("currency", {
                    required: true,
                  })}
                >
                  <option value="">--Select--</option>
                  {countries.all.map((currencies, i) =>
                    currencies.currencies.length ? (
                      <option value={currencies.currencies[0]} key={i}>
                        {currencies.currencies[0]}
                      </option>
                    ) : null
                  )}
                </select>
                {errors.currency?.type === "required" && (
                  <p className="error-message">Currency is required</p>
                )}
              </div>
            </div>
            {/* Inputs Row 5 Starts */}
            <div className="row">
              <div className="col-md-5">
                <label className="label-color-prof mt-3">Annual Salary</label>
                <input
                  className="form-control input-color-prof"
                  {...register("salary", {
                    required: true,
                  })}
                  placeholder="Min-Max (1000 - 10000)"
                />
                {errors.salary?.type === "required" && (
                  <p className="error-message">Salary is required</p>
                )}
              </div>

              <div className="col-md-2"></div>
              {watchAllFields.employed_status == 0 ? null : (
                <div className="col-md-5">
                  <label className="label-color-prof mt-3">
                    Started working from
                  </label>
                  <input
                    className="form-control input-color-prof"
                    {...register("working_from", {
                      required: true,
                    })}
                    placeholder="ex. 03/2018 - 03/2020"
                    type="date"
                  />
                  {errors.working_from?.type === "required" && (
                    <p className="error-message">Working from is required</p>
                  )}
                </div>
              )}
            </div>
            {/* Inputs Row 5 Ends */}

            {/* Inputs Row 6 Starts */}
            <div className="row">
              <div className="col-md-5">
                <label className="label-color-prof mt-3">Notice Period</label>
                <select
                  className="form-control input-color-prof"
                  {...register("availability", {
                    required: true,
                  })}
                >
                  <option value="">--Select--</option>
                  {formData?.availablity?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.ava_name}
                    </option>
                  ))}
                </select>
                {errors.availability?.type === "required" && (
                  <p className="error-message">Notice Period is required</p>
                )}
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5"></div>
            </div>
            {/* Inputs Row 6 Ends */}

            {/* Inputs Row 7 Button Starts */}
            <div className="row mt-5">
              <div className="col-md-2"></div>
              <div className="col-md-8 d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-warning prof-det-btn-main"
                >
                  Save and Continue
                </button>
              </div>
              <div className="col-md-2"></div>
            </div>
            {/* Inputs Row 7 Button Ends */}
          </div>
          {/* Main Background of Input Ends */}
          <div className="col-md-1"></div>
        </div>
      </form>
      {/* Row 3 Para Ends */}
      {props.professionalloginReducer.loading == true ? null : (
        <FullPageLoader />
      )}
      {props.professionalloginReducer.loading == false ? (
        <FullPageLoader />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  professionalloginReducer: state.professionalloginReducer,
  profileReducer: state.profileReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getProfessionalLogin: (userId) => dispatch(getProfessionalLogin(userId)),
  createProfessionalLogin: (userId, data, returnPath) =>
    dispatch(createProfessionalLogin(userId, data, returnPath)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalDetailsLogin);
