import React from "react";
import "./GetPremium.css";
import "bootstrap/dist/css/bootstrap.min.css";
import OkIcon from "../../Assests/ok-icon.svg";
import WrongIcon from "../../Assests/wrong-icon.svg";
import { getPricing } from "../../actions/pricingAction";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Nav2 from "../../Components/Nav2/Nav2";
import Footer2 from "../../Components/Footer/Footer2";
import MStripe from "../../stripeBtn";
import { Link } from "react-router-dom";
import PremiumBanner from "../../Components/PremiumBanner";
import Navbar from "../../Components/Navbar/Navbar";
import { LoginModalAction } from "../../actions/loginModalAction";
import GPay from "../../Components/GooglePay/Gpay";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";

function GetPremium(props) {
  useEffect(() => {
    loadGetPersonal(localStorage.getItem("auth_id"));
  }, []);
  const loadGetPersonal = async (userId) => {
    await props.getPricing(userId);
    return null;
  };

  return (
    <>
      {localStorage.getItem("auth_id") ? <Nav2 /> : <Navbar />}
      <div className="container mt-4">
        <div className="row d-flex justify-content-center">
          <div className="col-12">
            <h1
              className="font-weight-bold text-center"
              style={{ color: "#865ddd" }}
            >
              Packages
            </h1>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <h3 className="font-weight-bold">30 DAYS FREE TRIAL</h3>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          {props.pricingReducer.pricing.packages &&
          props.pricingReducer.pricing.packages.length > 0
            ? props.pricingReducer.pricing.packages.map((pri, index) => (
                <div
                  key={index}
                  className="col-lg-4 col-sm-12 shadow m-4 p-4 text-center d-flex justify-content-center align-items-center flex-column"
                  style={{ borderRadius: "15px" }}
                >
                  <h4 className="text-center font-weight-bold">{pri.name}</h4>
                  {pri.applicant_is_check === "not allow" ? null : (
                    <p>{pri.applicant_information_visible}</p>
                  )}
                  {pri.create_profile_is_check === "not allow" ? null : (
                    <p>{pri.create_profile}</p>
                  )}
                  {pri.messages_is_check === "not allow" ? null : (
                    <p>{pri.messages_description}</p>
                  )}
                  {pri.progress_is_check === "not allow" ? null : (
                    <p>{pri.progress_description}</p>
                  )}
                  <h2 className="cash-stan-main">
                    {pri.price != "FREE" ? (
                      <div className="style-4">
                        <del>{"USD " + pri.price}</del>
                      </div>
                    ) : (
                      pri.price
                    )}
                  </h2>
                  <p className="font-weight-bold">
                    Please contact us for individual corporate packages
                  </p>
                </div>
              ))
            : ""}
        </div>
        {props.pricingReducer.loading == false ? <FullPageLoader /> : null}
      </div>
      <Footer2 />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pricingReducer: state.pricingReducer,
  loginModalReducer: state.loginModalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPricing: (userId) => dispatch(getPricing(userId)),
  LoginModalAction: (toggle) => dispatch(LoginModalAction(toggle)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GetPremium);
