export const getProfile = (userId) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_END_POINT}/user/profile.php`, {
      method: "GET",
      headers: { "Content-Type": "application/json", auth_id: `${userId}` },
    })
      .then((res) => res.json())
      .then((response) => {
        const profile = response.data;
        const lang = response.data.profile.languages;
        localStorage.setItem("language", JSON.stringify(lang));
        dispatch({
          type: "GET_PROFILE",
          profile: profile,
          profileResponse: "got it",
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_PROFILE",
          profile: {},
          profileResponse: null,
        });
      });
  };
};
