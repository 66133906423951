import React, { useState, useEffect } from "react";
import Footer2 from "../../Components/Footer/Footer2";
import Nav2 from "../../Components/Nav2/Nav2";
import Navbar from "../../Components/Navbar/Navbar";
import ReactPlayer from "react-player";
import axios from "axios";

function Tips() {
  const [apiData, setApiData] = useState("");
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_END_POINT}/user/tips_vid.php`
    );
    setApiData(res.data.data.url);
  };
  console.log(apiData);
  return (
    <>
      {localStorage.getItem("auth_id") !== null &&
      localStorage.getItem("auth_id") !== undefined &&
      localStorage.getItem("auth_id") !== "" ? (
        <Nav2 />
      ) : (
        <Navbar />
      )}
      <div className="container">
        <div className="row mt-5">
          <h1 className="font-weight-bold" style={{ color: "#865ddd" }}>
            Tips & Recommendations
          </h1>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 mb-5 mt-5">
            {localStorage.getItem("userCurrentLocation") ? (
              localStorage.getItem("userCurrentLocation") == "PK" ? (
                <ReactPlayer
                  url={"https://www.youtube.com/watch?v=dlCW95MZo-Q"}
                  playing={true}
                  controls
                  className="react-player"
                />
              ) : (
                <ReactPlayer
                  url={"https://www.youtube.com/watch?v=-cuKlxl9lic"}
                  playing={true}
                  controls
                  className="react-player"
                />
              )
            ) : (
              <ReactPlayer
                url={"https://www.youtube.com/watch?v=-cuKlxl9lic"}
                playing={true}
                controls
                className="react-player"
              />
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mb-5">
          <div className="col-md-12">
            <p className="font-weight-bold text-danger">IMPORTANT</p>
            <p className="font-weight-bold text-danger">
              In order to protect your Privacy and Identity, we strongly
              recommend that you do not share any personal details such as full
              name, phone number or email in your video resume
            </p>
            <p>The video shouldn’t be longer than 120 seconds.</p>
            <h6 style={{ fontWeight: 800, textTransform: "uppercase" }}>
              Guidelines for video:
            </h6>
            <ol className="mb-3">
              <li>
                What will you bring to the company? (Focus on key requirements
                for the job)
              </li>
              <li>
                Why should we hire you? (Mention both your hard and soft skills)
              </li>
              <li>
                Explain how your skills and attributes would benefit the
                company? (Maybe highlight a few achievements)
              </li>
            </ol>
            <p>
              Please make sure you record your video.in 16:9 format fhd (full hd
              30fps). If you upload your video, please make sure your upload
              videos are in 16:9 fhd 30fps (full hd 30 frames per second format)
              and video size should not be more than 300mb.
            </p>
            <h6 style={{ fontWeight: 800, textTransform: "uppercase" }}>
              Choose an appropriate location
            </h6>
            <p>
              Make sure to record the video in an appropriate room where there
              won’t be any noises, and you won’t get distracted. If you have
              pets at home, keep them in a different room during the interview.
              A barking dog or a meowing cat noise in the background doesn’t
              make you look very professional. Also, a plain white wall as a
              backdrop is ideal for video interviews.
            </p>
            <h6 style={{ fontWeight: 800, textTransform: "uppercase" }}>
              Try your hardware before the interview
            </h6>
            <p>
              Try all of your equipment including your headsets, microphone and
              internet connection to make sure that they are properly working.
              Set up your camera, so it is focused on your eye level. Verify
              that your sound is clear. You can test it by making a video call
              with a friend. Also, confirm that you can hear the other person
              via your headphones.
            </p>
            <h6 style={{ fontWeight: 800, textTransform: "uppercase" }}>
              Appearance
            </h6>
            <p>
              Make yourself as professional as possible, turn on good lighting
              and set the camera in front of your face. All other angles look
              less advantageous. Imagine that the recruiter is you and wonder
              what a promising candidate for a specific position should look
              like?
            </p>
            <h6 style={{ fontWeight: 800, textTransform: "uppercase" }}>
              Time management
            </h6>
            <p>
              You have 120 seconds / two minutes to record this video resume. In
              order to use this time effectively and to make the best impression
              start talking about your education, work experience and any
              special skills that you may have, after which you can end your
              recording with a positive statement as to why the employer should
              hire you and why you will be an asset to the company.
            </p>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Tips;
