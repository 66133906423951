import React from "react";
import "./PersonalDetails.css";
import { useState, useEffect } from "react";
import { getPersonal } from "../../actions/Personalaction";
import { connect } from "react-redux";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import axios from "axios";
import { countries } from "country-data";
import IntlTelInput from "react-intl-tel-input";

function PersonalDetails(props) {
  const [loader, setLoader] = useState(true);
  const [imgData, setImgData] = useState(null);
  const [file, setFile] = useState(null);
  const [first_name, setFirst_name] = useState(null);
  const [last_name, setLast_name] = useState(null);
  const [email, setEmail] = useState(null);
  const [num, setNum] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState(null);
  const [marital_stat, setMarital_stat] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [code, setCode] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [checkId, setCheckId] = useState([]);
  const [lingo, setLingo] = useState([]);
  const [lingCounter, setLingCounter] = useState(0);
  const [selectedCountryStates, setSelectedCountryStates] = useState([]);
  const [selectedCountryStatesCity, setSelectedCountryStatesCity] = useState(
    []
  );
  const [countriesCode, setCountriesCode] = useState([]);
  const [dataStates, setDataStates] = useState("");

  useEffect(async () => {
    setLoader(false);
    await props.getPersonal(localStorage.getItem("auth_id"));
    let codes = [];
    countries.all.forEach((x) => {
      if (x.countryCallingCodes[0]) {
        let object = {
          name: x.name + " " + x.countryCallingCodes[0],
          value: x.countryCallingCodes[0],
        };
        codes.push(object);
      }
    });
    setCountriesCode((prev) => (prev = codes));
  }, []);
  useEffect(async () => {
    await getAllStatesData();
    setImgData(props?.personalReducer?.personal?.stored_values?.dp);
    setFile(props?.personalReducer?.personal?.stored_values?.dp);
    setFirst_name(props?.personalReducer?.personal?.stored_values?.first_name);
    setLast_name(props?.personalReducer?.personal?.stored_values?.last_name);
    setEmail(props?.personalReducer?.personal?.stored_values?.email);
    setNum(props?.personalReducer?.personal?.stored_values?.num);
    setDob(props?.personalReducer?.personal?.stored_values?.dob);
    setGender(props?.personalReducer?.personal?.stored_values?.gender);
    setMarital_stat(
      props?.personalReducer?.personal?.stored_values?.marital_status?.id
    );
    setState(props?.personalReducer?.personal?.stored_values?.state?.id);
    setCountry(props?.personalReducer?.personal?.stored_values?.country?.id);
    setCity(props?.personalReducer?.personal?.stored_values?.city?.id);
    setNationality(
      props?.personalReducer?.personal?.stored_values?.nationality.id
    );
    setCode(props?.personalReducer?.personal?.stored_values?.num_code);
    setSelectedCountryCode(
      (prev) =>
        (prev = countries.all
          .find(
            (x) =>
              x.countryCallingCodes[0] ==
              props?.personalReducer?.personal?.stored_values?.num_code
          )
          .alpha2.toLowerCase())
    );
    setLoader(true);
  }, [props.personalReducer.personal.stored_values]);
  const getAllStatesData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_END_POINT}/user/get_country_list.php`)
      .then((res) => {
        setDataStates(res.data.data);
        axios
          .post(
            `${process.env.REACT_APP_API_END_POINT}/user/fetch_state_list.php`,
            {
              country_id:
                props?.personalReducer?.personal?.stored_values?.country?.id,
            }
          )
          .then((res) => {
            setSelectedCountryStates(res.data.data.state_list);
            axios
              .post(
                `${process.env.REACT_APP_API_END_POINT}/user/fetch_city_list.php`,
                {
                  country_id:
                    props?.personalReducer?.personal?.stored_values?.country
                      ?.id,
                  state_id:
                    props?.personalReducer?.personal?.stored_values?.state?.id,
                }
              )
              .then((res) => {
                setSelectedCountryStatesCity(res.data.data.city_list);
              });
          });
      });
  };

  let handleTest = (e) => {
    if (lingo.length == 0 && lingCounter == 0) {
      let preIdList = [];
      let previousData = [
        ...props.personalReducer.personal.stored_values.languages,
      ];
      for (let x = 0; x < previousData.length; x++) {
        preIdList.push(previousData[x].id);
      }
      let temp = preIdList.filter((data) => data == e);
      if (temp.length > 0) {
        return;
      }
      if (preIdList.length == 3) {
        alert("You can only enter three languages!");
        setLingo(preIdList);
      } else {
        preIdList.push(e);
        setLingo(preIdList);
      }

      setLingCounter(1);
    } else if (lingo.length == 0 && lingCounter == 1) {
      let preIdList = [];
      let len = e.length;
      setLingo(preIdList);
      preIdList.push(e);
    } else if (lingo.length > 0) {
      let tempp = [...lingo];
      let check = tempp.filter((data) => data == e);
      if (check.length > 0) {
        return;
      } else {
        if (tempp.length == 3) {
          alert("You can only enter three languages!");
        } else {
          tempp.push(e);
          setLingo(tempp);
        }
      }
    }
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleFile = async (e) => {
    if (num && num.toString().length < 7) {
      alert("Mobile Number is Invalid");
      return;
    }
    const reg =
      /^([a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/  ]*)@([a-zA-Z]+)\.([a-zA-Z]+).([a-zA-Z]+)$/;
    if (email && reg.test(email) === false) {
      alert("Email is invalid");
    } else {
      let formData = new FormData(); //formdata object
      formData.append("file", file);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("email", email);
      formData.append("num", num);
      formData.append("num_code", code);
      formData.append("dob", dob);
      formData.append("gender", gender);
      formData.append("marital_stat", marital_stat);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("nationality", nationality);

      if (checkId && checkId.length > 0) {
        for (let i = 0; i < checkId.length; i++) {
          formData.append("lang[]", checkId[i]);
        }
      }
      if (lingCounter == 0) {
        let apiList = [];
        let list = props.personalReducer.personal.stored_values.languages;
        for (let i = 0; i < list.length; i++) {
          apiList.push(list[i].id);
        }
        for (let i = 0; i < apiList.length; i++) {
          formData.append("lang[]", apiList[i]);
        }
      } else if (lingCounter == 1) {
        for (let l = 0; l < lingo.length; l++) {
          formData.append("lang[]", lingo[l]);
        }
      }

      fetch(`${process.env.REACT_APP_API_END_POINT}/user/add_profile.php`, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          auth_id: localStorage.getItem("auth_id"),
        },
      })
        .then((response) => {
          window.location = "/professionaldetails";
          setLoader(true);
        })
        .catch((error) => {
          setLoader(true);
        });
    }
  };

  const handleLastName = (e) => {
    setLast_name(e.target.value);
  };

  const handleDateInput = (e) => {
    setDob(e.target.value.toString());
  };

  const setEmailInput = (e) => {
    setEmail(e.target.value);
  };

  const handleNationality = (e) => {
    setNationality(e.target.value);
  };

  const handleCountry = (e) => {
    setCountry(e.target.value);
    setState("");
    setCity("");
    if (e.target.value) {
      axios
        .post(
          `${process.env.REACT_APP_API_END_POINT}/user/fetch_state_list.php`,
          {
            country_id: e.target.value,
          }
        )
        .then((res) => {
          setSelectedCountryStates(res.data.data.state_list);
        });
    }
  };

  const handleState = (e) => {
    setState(e.target.value);
    if (e.target.value) {
      axios
        .post(
          `${process.env.REACT_APP_API_END_POINT}/user/fetch_city_list.php`,
          {
            country_id: country,
            state_id: e.target.value,
          }
        )
        .then((res) => {
          setSelectedCountryStatesCity(res.data.data.city_list);
        });
    }
  };
  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleMartialStatus = (e) => {
    setMarital_stat(e.target.value);
  };

  const disableNewNumber = () => {
    if (props?.personalReducer?.personal?.stored_values?.num != num)
      return true;
    return false;
  };

  const deleteKeySkills = (e) => {
    if (lingCounter == 0) {
      let tempId = [];
      let temp = [...props.personalReducer.personal.stored_values.languages];
      for (let n = 0; n < temp.length; n++) {
        tempId.push(temp[n].id);
      }
      let check = tempId.filter((data) => data !== e);
      setLingo(check);
      setLingCounter(1);
    } else if (lingCounter == 1) {
      let temp = [...lingo];
      let check = temp.filter((data) => data !== e);
      setLingo(check);
    }
  };
  if (!loader) {
    return <FullPageLoader />;
  }
  return (
    <>
      <div className="container mt-5">
        <div className="row mt-5">
          <div className="col-md-2"></div>
          <h1 className="col-md-8 d-flex justify-content-center per-det-head">
            Personal Details
          </h1>
          <div className="col-md-2"></div>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10 shadow p-5 per-det-main">
              {props.personalReducer.personal &&
              props.personalReducer.personal == null ? (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div
                      className="spinner-border text-secondary"
                      role="status"
                    >
                      <span className="sr-only">No Jobs</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-5"></div>
                    <div className="col-md-2">
                      <input
                        type="file"
                        id="image"
                        className="inputs"
                        onChange={(e) => onChangePicture(e)}
                        accept="image/png, image/gif, image/jpeg"
                      />
                      {imgData ? (
                        <img
                          className="border p-3 rounded"
                          src={imgData}
                          style={{ objectFit: "cover" }}
                          width="100%"
                          height="100%"
                        />
                      ) : (
                        <i className="fas fa-user fa-3x border rounded p-4"></i>
                      )}
                    </div>
                    <div className="col-md-5"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">First name</label>
                      <input
                        row="1"
                        col="1"
                        onChange={(e) => setFirst_name(e.target.value)}
                        value={first_name}
                        id="inp"
                        type="text"
                        className="form-control per-det-input"
                        placeholder="Type your first name"
                        required
                      />
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">Last name</label>
                      <input
                        onChange={handleLastName}
                        type="text"
                        value={last_name}
                        className="form-control per-det-input"
                        placeholder="Type your last name"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 hjk">
                      <label className="per-det-label mt-3">Languages</label>
                      <select
                        className="form-control"
                        onChange={(e) => handleTest(e.target.value)}
                      >
                        <option selected>Select</option>
                        {props.personalReducer.personal
                          ? props.personalReducer.personal.languages &&
                            props.personalReducer.personal.languages.length > 0
                            ? props.personalReducer.personal.languages.map(
                                (l) => (
                                  <>
                                    <option value={l.id} key={l.id}>
                                      {l.label}
                                    </option>
                                    <br />
                                  </>
                                )
                              )
                            : ""
                          : ""}
                      </select>

                      {props.personalReducer.personal.stored_values &&
                      lingo.length < 1
                        ? lingCounter == 0
                          ? props.personalReducer.personal.stored_values.languages.map(
                              (lang) => (
                                <button
                                  onClick={() => deleteKeySkills(lang.id)}
                                  className="btn btn-light mt-2 btn-color-key mx-1"
                                  id="btnn"
                                  style={{ fontSize: "12px" }}
                                >
                                  <span className="btn-label">
                                    <i className="far fa-trash pr-1"></i>
                                  </span>

                                  {lang !== null && lang !== undefined
                                    ? lang.label
                                    : ""}
                                </button>
                              )
                            )
                          : ""
                        : lingo.map((lang) => (
                            <button
                              onClick={() => deleteKeySkills(lang)}
                              className="btn btn-light mt-2 btn-color-key mx-1"
                              id="btnn"
                              style={{ fontSize: "12px" }}
                            >
                              <span className="btn-label">
                                <i className="far fa-trash pr-1"></i>
                              </span>

                              {props.personalReducer.personal.languages &&
                              props.personalReducer.personal.languages.length >
                                0
                                ? props.personalReducer.personal.languages.filter(
                                    (data) => data.id == lang
                                  ).length > 0
                                  ? props.personalReducer.personal.languages.filter(
                                      (data) => data.id == lang
                                    )[0].label
                                  : ""
                                : ""}
                            </button>
                          ))}
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">Phone Number</label>
                      <div className="row">
                        <div className="col-12 d-flex pr-0">
                          {loader ? (
                            <IntlTelInput
                              preferredCountries={["pk"]}
                              value={num}
                              inputClassName="form-control per-det-input"
                              defaultCountry={selectedCountryCode}
                              onPhoneNumberChange={(
                                status,
                                phoneNumber,
                                country
                              ) => {
                                setCode("+" + country.dialCode);
                                setNum(phoneNumber);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">Country</label>
                      <select
                        value={country}
                        onChange={handleCountry}
                        className="form-control per-det-input"
                        aria-label="Default select example"
                        required
                      >
                        <option>Select Your Country</option>
                        {dataStates.country && dataStates.country.length > 0 ? (
                          dataStates.country.map((con) => (
                            <option value={con.country_id}>
                              {con.country_name !== null &&
                              con.country_name !== undefined ? (
                                con.country_name
                              ) : (
                                <div>
                                  <div
                                    className="spinner-border text-secondary"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              )}
                            </option>
                          ))
                        ) : (
                          <div>
                            <div
                              className="spinner-border text-secondary"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                      </select>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">
                        State / Province
                      </label>
                      <select
                        onChange={handleState}
                        value={state}
                        className="form-control per-det-input"
                        aria-label="Default select example"
                        required
                      >
                        <option>Select your state</option>
                        {selectedCountryStates &&
                        selectedCountryStates.length > 0
                          ? selectedCountryStates.map((f) => (
                              <option value={f.state_id}>{f.state_name}</option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">City</label>
                      <select
                        onChange={handleCity}
                        value={city}
                        className="form-control per-det-input"
                        aria-label="Default select example"
                        required
                      >
                        <option selected>Select Your City</option>
                        {selectedCountryStatesCity &&
                        selectedCountryStatesCity.length > 0
                          ? selectedCountryStatesCity.map((f) => (
                              <option value={f.city_id}>{f.city_name}</option>
                            ))
                          : ""}
                      </select>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">Nationality</label>
                      <select
                        onChange={handleNationality}
                        value={nationality}
                        className="form-control"
                        aria-label="Default select example"
                        required
                      >
                        <option>Select Your Nationality</option>
                        {props.personalReducer.personal.nationality &&
                        props.personalReducer.personal.nationality.length >
                          0 ? (
                          props.personalReducer.personal.nationality.map(
                            (nat) => (
                              <option value={nat.id}>
                                {nat.nat_name !== null &&
                                nat.nat_name !== undefined ? (
                                  nat.nat_name
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-secondary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </option>
                            )
                          )
                        ) : (
                          <div>
                            <div
                              className="spinner-border text-secondary"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                      </select>
                    </div>

                    {/* here */}
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">
                        Date of Birth
                      </label>
                      <input
                        onChange={handleDateInput}
                        value={dob}
                        type="date"
                        className="form-control per-det-input"
                        placeholder="Enter your current functional area"
                        required
                      />
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">
                        Email Address
                      </label>
                      <input
                        onChange={setEmailInput}
                        value={email}
                        type="email"
                        className="form-control per-det-input"
                        placeholder="Enter your email address"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    {/* here */}
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">Gender</label>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="male"
                          value={1}
                          checked={gender == 1 ? true : null}
                          name="gender"
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <label
                          className="form-check-label per-det-input"
                          htmlFor="male"
                        >
                          Male
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="female"
                          value={0}
                          checked={gender == 0 ? true : null}
                          name="gender"
                          onChange={(e) => setGender(e.target.value)}
                        />

                        <label
                          className="form-check-label per-det-input"
                          htmlFor="female"
                        >
                          Female
                        </label>
                      </div>
                    </div>

                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <label className="per-det-label mt-3">
                        Marital Status
                      </label>
                      <select
                        value={marital_stat}
                        onChange={handleMartialStatus}
                        className="form-control per-det-input"
                        aria-label="Default select example"
                        style={{
                          border: "1px solid #c8c8c8",
                          borderRadius: "5px",
                          color: "#000",
                        }}
                        required
                      >
                        <option>Select your marital status (optional)</option>
                        {props.personalReducer.personal.marital_statuses &&
                        props.personalReducer.personal.marital_statuses.length >
                          0 ? (
                          props.personalReducer.personal.marital_statuses.map(
                            (mar) => (
                              <option value={mar.id}>
                                {mar.m_name !== null &&
                                mar.m_name !== undefined ? (
                                  mar.m_name
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-secondary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </option>
                            )
                          )
                        ) : (
                          <div>
                            <div
                              className="spinner-border text-secondary"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-5"></div>

                  <div className="row mt-5">
                    <div className="col-md-12 d-flex justify-content-center">
                      <input
                        onClick={() => handleFile()}
                        type="button"
                        value="Save and Continue"
                        id="persDet"
                        className="btn btn-warning per-det-btn"
                        disabled={disableNewNumber()}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-1"></div>
        </form>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  registerReducer: state.registerReducer,
  personalReducer: state.personalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPersonal: (userId) => dispatch(getPersonal(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
