import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./QualificationDetails.css";
import { getQualificationLogin } from "../../actions/qualificationloginAction";
import { createQualificationLogin } from "../../actions/qualificationloginAction";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

function QualifcicationDetailsLogin(props) {
  const [formData, setFormData] = useState({});
  let param = useLocation().search;
  param = param.split("=");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    props.createQualificationLogin(
      localStorage.getItem("auth_id"),
      data,
      param[1] === "edit" ? "/profile" : "/skills"
    );
  };
  const watchAllFields = watch();
  useEffect(() => {
    props
      .getQualificationLogin(localStorage.getItem("auth_id"))
      .then(async (res) => {
        setFormData((prev) => (prev = res));
        let { stored_values } = res;
        let resetData = {
          course: stored_values.course?.id,
          course_spec: stored_values.course_spec?.id,
          institute_location: stored_values.institute_location?.id,
          qualification: stored_values.qualification?.qual_id,
          year: stored_values.year.id,
          institute_name: stored_values.institute_name,
        };
        const selectedCourseType = await res.course_vise_education?.find(
          (x) => x.qual_id == resetData.qualification
        )?.qual_courses;
        const selectedCourseSpec = await selectedCourseType?.find(
          (x) => x.id == resetData.course
        )?.spec;
        await setFormData((prev) => ({
          ...prev,
          selectedCourseType: selectedCourseType,
          selectedCourseSpec: selectedCourseSpec,
        }));
        await reset(resetData);
      });
  }, []);

  if (
    (props.qualificationloginReducer.loading != true ||
      props.qualificationloginReducer.loadingGet == false) &&
    !props.qualificationloginReducer.qualificationlog.stored_values
  ) {
    return <FullPageLoader />;
  }
  return (
    <div className="container mt-5">
      <div className="row mt-5">
        <div className="col-md-2"></div>
        <h1 className="col-md-8 d-flex justify-content-center qual-det-head">
          Qualification Details
        </h1>
        <div className="col-md-2"></div>
      </div>
      {/* Row 1 for Heading Ends */}

      {/* Row 2 for Para Starts */}
      <div className="row">
        <div className="col-md-2"></div>
        <p className="col-md-8 d-flex justify-content-center qual-det-para">
          Provide your educational information
        </p>
        <div className="col-md-2"></div>
      </div>
      {/* Row 2 for Para Ends */}

      {/* Row 3 Para Starts */}
      <form
        className="shadow p-5 per-det-main"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-md-1"></div>
          {/* Main Background of Input starts */}
          <div className="col-md-10 shadow p-5 qual-det-main">
            {/* Inputs Row 1 Starts */}
            <div className="row">
              <div className="col-md-5">
                <label className="qual-det-label mt-3">Qualification</label>
                <select
                  {...register("qualification", {
                    required: true,
                    onChange: (e) => {
                      const selectedCourseType =
                        formData.course_vise_education?.find(
                          (x) => x.qual_id == e.target.value
                        )?.qual_courses;
                      setFormData((prev) => ({
                        ...prev,
                        selectedCourseType: selectedCourseType,
                      }));
                    },
                  })}
                  className="form-control qual-det-input"
                >
                  <option value="">Select Your Qualification</option>
                  {formData?.course_vise_education?.map((x, i) => (
                    <option value={x.qual_id} key={i}>
                      {x.qual_name}
                    </option>
                  ))}
                </select>
                {errors.qualification?.type === "required" && (
                  <p className="error-message">Qualification is required</p>
                )}
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <label className="qual-det-label mt-3">Institute Name</label>
                <input
                  {...register("institute_name", {
                    required: true,
                  })}
                  className="form-control qual-det-input"
                  placeholder="Enter institute name"
                />
                {errors.institute_name?.type === "required" && (
                  <p className="error-message">Institute Name is required</p>
                )}
              </div>
            </div>
            {/* Inputs Row 1 Ends */}

            {/* Inputs Row 2 Starts */}
            <div className="row">
              <div className="col-md-5">
                <label className="qual-det-label mt-3">Course Type</label>
                <select
                  {...register("course", {
                    required: true,
                    onChange: (e) => {
                      const selectedCourseSpec =
                        formData.selectedCourseType?.find(
                          (x) => x.id == e.target.value
                        )?.spec;
                      setFormData((prev) => ({
                        ...prev,
                        selectedCourseSpec: selectedCourseSpec,
                      }));
                    },
                  })}
                  className="form-control qual-det-input"
                  aria-label="Default select example"
                >
                  <option value="">Select Your Course Type</option>
                  {formData?.selectedCourseType?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.name}
                    </option>
                  ))}
                </select>
                {errors.course?.type === "required" && (
                  <p className="error-message">Course Type is required</p>
                )}
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <label className="qual-det-label mt-3">
                  Institute Location
                </label>
                <select
                  {...register("institute_location", {
                    required: true,
                  })}
                  className="form-control qual-det-input"
                  aria-label="Default select example"
                >
                  <option value="">Select Your Institute Location</option>
                  {formData?.location?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.loc_name}
                    </option>
                  ))}
                </select>
                {errors.institute_location?.type === "required" && (
                  <p className="error-message">
                    Institute Location is required
                  </p>
                )}
              </div>
            </div>
            {/* Inputs Row 2 Ends */}

            {/* Inputs Row 3 Starts */}
            <div className="row">
              <div className="col-md-5">
                <label className="qual-det-label mt-3">
                  Course Specialization
                </label>
                <select
                  {...register("course_spec", {
                    required: true,
                  })}
                  className="form-control qual-det-input"
                  aria-label="Default select example"
                >
                  <option value="">Select Your Course Specialization</option>
                  {formData?.selectedCourseSpec?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.name}
                    </option>
                  ))}
                </select>
                {errors.course_spec?.type === "required" && (
                  <p className="error-message">
                    Course Specialization is required
                  </p>
                )}
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <label className="qual-det-label mt-3">
                  Course Completion Year
                </label>
                <select
                  {...register("year", {
                    required: true,
                  })}
                  className="form-control qual-det-input"
                  aria-label="Default select example"
                >
                  <option value="">Select Your Completion Year</option>
                  {formData?.years?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.year}
                    </option>
                  ))}
                </select>
                {errors.year?.type === "required" && (
                  <p className="error-message">
                    Course Completion Year is required
                  </p>
                )}
              </div>
            </div>
            {/* Inputs Row 3 Ends */}

            {/* Inputs Row 7 Button Starts */}
            <div className="row mt-5">
              <div className="col-md-2"></div>
              <div className="col-md-8 d-flex justify-content-center">
                <button type="submit" className="btn btn-warning qual-det-btn">
                  Save and Continue
                </button>
              </div>
              <div className="col-md-2"></div>
            </div>
            {/* Inputs Row 7 Button Ends */}
          </div>
          {/* Main Background of Input Ends */}
          <div className="col-md-1"></div>
        </div>
      </form>
      {/* Row 3 Para Ends */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  qualificationloginReducer: state.qualificationloginReducer,
  profileReducer: state.profileReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getQualificationLogin: (userId) => dispatch(getQualificationLogin(userId)),
  createQualificationLogin: (userId, data, returnPath) =>
    dispatch(createQualificationLogin(userId, data, returnPath)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualifcicationDetailsLogin);
