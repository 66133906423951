const config = require("../helpers/config.json");
export const getQualificationLogin = (userId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_END_POINT}/user/add_qualification_get.php`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json", auth_id: `${userId}` },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          const qualificationlog = response.data;
          resolve(qualificationlog);
          dispatch({
            type: "GET_QUALIFICATIONLOG",
            qualificationlog: qualificationlog,
            qualificationlogResponse: "got it",
            loading: true,
            loadingGet: true,
          });
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
          dispatch({
            type: "GET_QUALIFICATIONLOG",
            qualificationlog: {},
            qualificationlogResponse: null,
            loading: true,
            loadingGet: true,
          });
        });
    });
  };
};

export const createQualificationLogin = (userId, data, returnPath) => {
  return (dispatch) => {
    dispatch({
      type: "QUALIFICATIONLOG_RESET",
      loading: false,
    });

    fetch(
      `${process.env.REACT_APP_API_END_POINT}/user/add_qualification_post.php`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", auth_id: `${userId}` },
        body: JSON.stringify({
          qualification:
            data.qualification !== null
              ? Number(data.qualification)
              : data.qualification,
          course: data.course !== null ? Number(data.course) : data.course,
          course_spec:
            data.course_spec !== null
              ? Number(data.course_spec)
              : data.course_spec,
          institute_name: data.institute_name,
          institute_location:
            data.institute_location !== null
              ? Number(data.institute_location)
              : data.institute_location,
          year: data.year !== null ? Number(data.year) : data.year,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        dispatch({
          type: "QUALIFICATIONLOG_SUCCESS",
          qualificationlogResponse: response,
          loading: true,
        });
        window.location = returnPath;
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "QUALIFICATIONLOG_FAIL",
          qualificationlogResponse: "creation failed",
          loading: true,
        });
        alert("Please Check Your Internet Connection...");
      });
  };
};
