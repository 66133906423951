import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import "./JobsDetail.css";
import Briefcase from "../../Assests/topmanagment/briefcase.svg";
import Location from "../../Assests/topmanagment/location.svg";
import Pen from "../../Assests/topmanagment/pen.svg";
import Wallet from "../../Assests/topmanagment/wallet.svg";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { getJobDetailLogin } from "../../actions/jobdetailloginAction";
import { SignIn, facebookLogin, googleLogin } from "../../actions/authAction";
import { createRegister } from "../../actions/registerAction";
import { createForgetPassword } from "../../actions/forgetPasswordAction";
import { connect } from "react-redux";
import Navbar from "../../Components/Navbar/Navbar";
import FooterTwo from "../../Components/Footer/Footer2";
import Modal from "react-modal";
import Google from "../../Assests/google-register.svg";
import Facebook from "../../Assests/facebook.svg";
import LinkedIn from "../../Assests/linked-in-register.svg";
import LinkedInPage from "../../getURL";
import FullPageLoader from "../../Components/fullpageloader/fullPageLoader";
import { Helmet } from "react-helmet";
import {
  LoginModalAction,
  RegisterModalAction,
} from "../../actions/loginModalAction";

Modal.setAppElement("#root");

function JobsDetailLogin(props) {
  const search = useLocation().search;
  const compIds = new URLSearchParams(search).get("id");
  const [LoginmodalIsOpen, setLoginModalIsOpen] = useState(false);
  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);
  const [forgetModalIsOpen, setForgetModalIsOpen] = useState(false);
  const [username, setusername] = useState("");
  const [passwordlog, setpasswordlog] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confrimpassword, setConfirmPassword] = useState("");
  const [termsCheck, setTermsCheck] = useState("");
  const [ForgetEmail, setForgetEmail] = useState("");
  const ForgetFunctionality = () => {
    setForgetModalIsOpen(true);
    setLoginModalIsOpen(false);
  };
  const ForgetFunctionality2 = () => {
    setLoginModalIsOpen(true);
    setForgetModalIsOpen(false);
  };

  const registermodal = () => {
    setLoginModalIsOpen(true);
    setRegisterModalIsOpen(false);
  };
  const registermodal2 = () => {
    setRegisterModalIsOpen(true);
    setLoginModalIsOpen(false);
  };
  const addNewRegister = (e) => {
    e.preventDefault();
    console.log("qwerty");
    if (confrimpassword === password) {
      if (number.toString().length < 7) {
        alert("Mobile Number is Invalid");
        return;
      }
      props.createRegister(first_name, last_name, email, number, password);
    } else {
      alert("Confim Password Not Matched....");
    }
    // window.location.reload(false);
  };

  const handleName = (e) => {
    setFirst_name(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleLastName = (e) => {
    setLast_name(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handlePhone = (e) => {
    setNumber(e.target.value);
  };
  const onSubmit = async (username, passwordlog) => {
    // console.log("Success:", props.auth.loading);
    await props.SignIn(username, passwordlog);
  };

  const newForget = async () => {
    console.log("qwerty", ForgetEmail);
    await props.createForgetPassword(ForgetEmail);
    // window.location.reload(false);
  };

  useEffect(() => {
    loadGetProfessional(localStorage.getItem("auth_id"), compIds);
  }, []);
  const loadGetProfessional = async (userId, id) => {
    await props.getJobDetailLogin(userId, id);
    return null;
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {props.jobdetailloginReducer.jobdetail.result
            ? props.jobdetailloginReducer.jobdetail.result.job_title !== null &&
              props.jobdetailloginReducer.jobdetail.result.job_title !==
                undefined
              ? props.jobdetailloginReducer.jobdetail.result.job_title
                  .charAt(0)
                  .toUpperCase() +
                props.jobdetailloginReducer.jobdetail.result.job_title
                  .substr(1)
                  .toLowerCase()
              : "Job Title"
            : "Job Title"}
        </title>
        <meta
          name="description"
          content={
            props.jobdetailloginReducer.jobdetail.result
              ? props.jobdetailloginReducer.jobdetail.result.job_desc !==
                  null &&
                props.jobdetailloginReducer.jobdetail.result.job_desc !==
                  undefined
                ? props.jobdetailloginReducer.jobdetail.result.job_desc
                : "Description"
              : "Description"
          }
        />

        <meta property="fb:app_id" content="4290430027681963" />
        <meta property="og:type" content="job" />
        <meta
          property="og:url"
          content={`https://cvvlogs.com/${props?.jobdetailloginReducer?.jobdetail?.result?.id}`}
        />
        <meta
          property="og:title"
          content={
            props.jobdetailloginReducer.jobdetail.result
              ? props.jobdetailloginReducer.jobdetail.result.job_title !==
                  null &&
                props.jobdetailloginReducer.jobdetail.result.job_title !==
                  undefined
                ? props.jobdetailloginReducer.jobdetail.result.job_title
                    .charAt(0)
                    .toUpperCase() +
                  props.jobdetailloginReducer.jobdetail.result.job_title
                    .substr(1)
                    .toLowerCase()
                : "Job Title"
              : "Job Title"
          }
        />
        <meta
          property="og:image"
          content="https://cvvlogs.com/static/media/logo.8abd9021.png"
        />
        <meta
          property="og:description"
          content={
            props.jobdetailloginReducer.jobdetail.result
              ? props.jobdetailloginReducer.jobdetail.result.job_desc !==
                  null &&
                props.jobdetailloginReducer.jobdetail.result.job_desc !==
                  undefined
                ? props.jobdetailloginReducer.jobdetail.result.job_desc
                : "Description"
              : "Description"
          }
        />
      </Helmet>
      <Navbar />
      <div className="container my-5">
        {/* Job Head Starts */}
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div
              className="row mt-5 shadow p-3"
              style={{ borderRadius: "10px" }}
            >
              <div className="col-md-9">
                <h3 style={{ color: "#707070" }} className="mb-0">
                  {props.jobdetailloginReducer.jobdetail.result
                    ? props.jobdetailloginReducer.jobdetail.result.job_title !==
                        null &&
                      props.jobdetailloginReducer.jobdetail.result.job_title !==
                        undefined
                      ? props.jobdetailloginReducer.jobdetail.result.job_title
                          .charAt(0)
                          .toUpperCase() +
                        props.jobdetailloginReducer.jobdetail.result.job_title
                          .substr(1)
                          .toLowerCase()
                      : ""
                    : ""}
                </h3>
                <small style={{ color: "#c8c8c8", fontSize: "16px" }}>
                  {props.jobdetailloginReducer.jobdetail.result ? (
                    props.jobdetailloginReducer.jobdetail.result
                      .company_name !== null &&
                    props.jobdetailloginReducer.jobdetail.result
                      .company_name !== undefined ? (
                      props.jobdetailloginReducer.jobdetail.result.company_name
                    ) : (
                      ""
                    )
                  ) : (
                    <div>
                      <div
                        className="spinner-border text-secondary"
                        role="status"
                      >
                        <span className="sr-only">No Jobs</span>
                      </div>
                    </div>
                  )}
                </small>
              </div>
              <div className="col-md-3">
                <p
                  className="text-right mt-3"
                  style={{ fontSize: "14px", color: "#c8c8c8" }}
                >
                  Posted on{" "}
                  {props.jobdetailloginReducer.jobdetail.result
                    ? props.jobdetailloginReducer.jobdetail.result.posted_on !==
                        null &&
                      props.jobdetailloginReducer.jobdetail.result.posted_on !==
                        undefined
                      ? props.jobdetailloginReducer.jobdetail.result.posted_on
                      : ""
                    : ""}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
        {/* Job Head Ends */}

        {/* Job main Starts */}
        <div className="row mt-5">
          <div className="col-md-1"></div>
          <div
            className="col-md-10 shadow p-4"
            style={{ borderRadius: "10px" }}
          >
            <div className="row">
              <div className="col-md-3">
                <div className="row">
                  <div className="col-2">
                    <img src={Briefcase} alt="" />
                  </div>
                  <div className="col-10">
                    <small style={{ color: "#707070" }}>
                      {props.jobdetailloginReducer.jobdetail.result
                        ? props.jobdetailloginReducer.jobdetail.result.exp !==
                            null &&
                          props.jobdetailloginReducer.jobdetail.result.exp !==
                            undefined
                          ? props.jobdetailloginReducer.jobdetail.result.exp
                          : ""
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-2">
                    <img src={Location} alt="" />
                  </div>
                  <div className="col-10">
                    <small style={{ color: "#707070" }}>
                      {props.jobdetailloginReducer.jobdetail.result
                        ? props.jobdetailloginReducer.jobdetail.result
                            .country !== null &&
                          props.jobdetailloginReducer.jobdetail.result
                            .country !== undefined &&
                          props.jobdetailloginReducer.jobdetail.result
                            .country !== ""
                          ? props.jobdetailloginReducer.jobdetail.result
                              .country + ", "
                          : ""
                        : ""}{" "}
                      {props.jobdetailloginReducer.jobdetail.result
                        ? props.jobdetailloginReducer.jobdetail.result.state !==
                            null &&
                          props.jobdetailloginReducer.jobdetail.result.state !==
                            undefined &&
                          props.jobdetailloginReducer.jobdetail.result.state !==
                            ""
                          ? props.jobdetailloginReducer.jobdetail.result.state +
                            ", "
                          : ""
                        : ""}
                      {props.jobdetailloginReducer.jobdetail.result
                        ? props.jobdetailloginReducer.jobdetail.result.city !==
                            null &&
                          props.jobdetailloginReducer.jobdetail.result.city !==
                            undefined &&
                          props.jobdetailloginReducer.jobdetail.result.city !==
                            ""
                          ? props.jobdetailloginReducer.jobdetail.result.city
                          : ""
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-2">
                    <img src={Pen} alt="" />
                  </div>
                  <div className="col-10">
                    <small style={{ color: "#707070" }}>
                      {props?.jobdetailloginReducer?.jobdetail?.result?.industry
                        ? `${props?.jobdetailloginReducer?.jobdetail?.result?.industry}`
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-2">
                    <img src={Wallet} alt="" />
                  </div>
                  <div className="col-10">
                    <small style={{ color: "#707070" }}>
                      {props?.jobdetailloginReducer?.jobdetail?.result
                        ? `${props?.jobdetailloginReducer?.jobdetail?.result?.salary_min} - ${props?.jobdetailloginReducer?.jobdetail?.result?.salary_max} ${props?.jobdetailloginReducer?.jobdetail?.result?.curr}`
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <h3 style={{ color: "#707070" }}>Job Description</h3>
                <p
                  style={{ color: "#707070" }}
                  dangerouslySetInnerHTML={{
                    __html: props.jobdetailloginReducer.jobdetail.result
                      ? props.jobdetailloginReducer.jobdetail.result
                          .job_desc !== null &&
                        props.jobdetailloginReducer.jobdetail.result
                          .job_desc !== undefined
                        ? props.jobdetailloginReducer.jobdetail.result.job_desc
                        : ""
                      : "",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
        {/* Job main Ends */}

        {/* Job last div Starts */}
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div
              className="row mt-5 shadow p-3"
              style={{ borderRadius: "10px" }}
            >
              {props.jobdetailloginReducer?.jobdetail?.result?.gender ? (
                <div className="col-md-12 p-0 m-0">
                  <h6 className="mt-4 mb-0 p-0" style={{ color: "#707070" }}>
                    Gender
                  </h6>
                  <small style={{ color: "#707070" }}>
                    {props.jobdetailloginReducer?.jobdetail?.result?.gender}
                  </small>
                </div>
              ) : (
                ""
              )}

              <div className="col-md-12 p-0 m-0">
                <h6 className="mt-4 mb-0 p-0" style={{ color: "#707070" }}>
                  No of Positions
                </h6>
                <small style={{ color: "#707070" }}>
                  {" "}
                  {props.jobdetailloginReducer.jobdetail.result
                    ? props.jobdetailloginReducer.jobdetail.result.vacancy !==
                        null &&
                      props.jobdetailloginReducer.jobdetail.result.vacancy !==
                        undefined
                      ? props.jobdetailloginReducer.jobdetail.result.vacancy
                      : ""
                    : ""}
                </small>
              </div>
              <div className="col-md-12 p-0 m-0">
                <h6 className="mt-4 mb-0 p-0" style={{ color: "#707070" }}>
                  Career level
                </h6>
                <small style={{ color: "#707070" }}>
                  {" "}
                  {props.jobdetailloginReducer.jobdetail.result
                    ? props.jobdetailloginReducer.jobdetail.result
                        .functional_area !== null &&
                      props.jobdetailloginReducer.jobdetail.result
                        .functional_area !== undefined
                      ? props.jobdetailloginReducer.jobdetail.result
                          .functional_area
                      : ""
                    : ""}
                </small>
              </div>
              <div className="col-md-12 p-0 m-0">
                <h6 className="mt-4 mb-0 p-0" style={{ color: "#707070" }}>
                  Employer Details
                </h6>
                <small style={{ color: "#707070" }}>
                  {props.jobdetailloginReducer.jobdetail.result
                    ? props.jobdetailloginReducer.jobdetail.result
                        .company_name !== null &&
                      props.jobdetailloginReducer.jobdetail.result
                        .company_name !== undefined
                      ? props.jobdetailloginReducer.jobdetail.result
                          .company_name
                      : ""
                    : ""}
                </small>
              </div>

              <div className="col-md-12 p-0 m-0">
                <h6 className="mt-4 mb-0" style={{ color: "#707070" }}>
                  Job Type
                </h6>
                <small style={{ color: "#707070" }}>
                  {props.jobdetailloginReducer.jobdetail.result
                    ? props.jobdetailloginReducer.jobdetail.result.job_type !==
                        null &&
                      props.jobdetailloginReducer.jobdetail.result.job_type !==
                        undefined
                      ? props.jobdetailloginReducer.jobdetail.result.job_type
                      : ""
                    : ""}
                </small>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
        {/* Job last div Ends */}

        {/* Button Starts */}
        <div className="row mt-5 mb-5">
          <div className="col-md-5"></div>
          <div className="col-md-2">
            <button
              onClick={() => props.LoginModalAction(true)}
              className="btn btn-warning w-100 shadow"
              style={{
                color: "#fff",
                background: "#ffb44a",
                fontWeight: "bold",
              }}
            >
              Apply
            </button>
          </div>
          <div className="col-md-5"></div>
        </div>
        {/* Button Ends */}
      </div>
      <FooterTwo />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  jobdetailloginReducer: state.jobdetailloginReducer,
  registerReducer: state.registerReducer,
});

const mapDispatchToProps = (dispatch) => ({
  SignIn: (username, passwordlog) => dispatch(SignIn(username, passwordlog)),
  getJobDetailLogin: (userId, id) => dispatch(getJobDetailLogin(userId, id)),
  createRegister: (first_name, last_name, email, number, password) =>
    dispatch(createRegister(first_name, last_name, email, number, password)),
  createForgetPassword: (email) => dispatch(createForgetPassword(email)),
  LoginModalAction: (toggle) => dispatch(LoginModalAction(toggle)),
  RegisterModalAction: (toggle) => dispatch(RegisterModalAction(toggle)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JobsDetailLogin);
