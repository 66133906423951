const config = require('../helpers/config.json');

export const getUploadCvPdf = (userId) => {
    return (dispatch) => {
        /// get request
        fetch(`${process.env.REACT_APP_API_END_POINT}user/user_docs.php`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
        }).then(res => res.json()).then((response) => {
            const uploadCvPdf = response.data
            dispatch({
                type: "GET_UPLOADCVPDF",
                uploadCvPdf: uploadCvPdf,
                uploadCvPdfResponse: "got it"
            });
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: "GET_UPLOADCVPDF",
                uploadCvPdf: {},
                uploadCvPdfResponse: null
            });
            // alert("Please Check Your Internet Connection...")
        })

    }


}

