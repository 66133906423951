const config = require("../helpers/config.json");
export const getPersonalLogin = (userId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_END_POINT}/user/add_personal_get.php`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json", auth_id: `${userId}` },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          const personal = response.data;
          resolve(personal);
          dispatch({
            type: "GET_PERSONAL",
            personal: personal,
            personalResponse: "got it",
            loader: true,
          });
        })
        .catch((error) => {
          reject(error);
          console.log("error", error);
          dispatch({
            type: "GET_PERSONAL",
            personal: {},
            personalResponse: null,
            loader: true,
          });
        });
    });
  };
};
