export const getProfessionalLogin = (userId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_END_POINT}/user/edit_professional_detail.php`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json", auth_id: `${userId}` },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          const professional = response.data;
          resolve(professional);
          dispatch({
            type: "GET_PROFESSIONALLOG",
            professional: professional,
            professionallogResponse: "got it",
            loading: true,
          });
        })
        .catch((error) => {
          reject(error);
          dispatch({
            type: "GET_PROFESSIONALLOG",
            professional: {},
            professionallogResponse: null,
            loading: true,
          });
        });
    });
  };
};

export const createProfessionalLogin = (userId, data, returnPath) => {
  return (dispatch) => {
    dispatch({
      type: "PROFESSIONALLOG_RESET",
      loading: false,
    });

    let requestBody = JSON.stringify({
      exp_level:
        data.exp_level !== null ? Number(data.exp_level) : data.exp_level,
      industry: data.industry !== null ? Number(data.industry) : data.industry,
      functional_area:
        data.functional_area !== null
          ? Number(data.functional_area)
          : data.functional_area,
      wrk_lvl: data.wrk_lvl !== null ? Number(data.wrk_lvl) : data.wrk_lvl,
      currency: data.currency,
      salary: data.salary,
      availability:
        data.availability !== null
          ? Number(data.availability)
          : data.availability,
      employed_status:
        data.employed_status !== null
          ? Number(data.employed_status)
          : data.employed_status,
      curr_title: data.curr_title,
      curr_rec: data.curr_rec,
      employer_city:
        data.employer_city !== null
          ? Number(data.employer_city)
          : data.employer_city,
      working_from: data.working_from,
    });

    fetch(
      `${process.env.REACT_APP_API_END_POINT}/user/add_proffessional_detail.php`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          auth_id: `${userId}`,
        },
        body: requestBody,
      }
    )
      .then((response) => {
        dispatch({
          type: "PROFESSIONALLOG_SUCCESS",
          professionallogResponse: response,
          loading: true,
        });
        window.location = returnPath;
      })
      .catch((error) => {
        dispatch({
          type: "PROFESSIONALLOG_FAIL",
          professionallogResponse: "creation failed",
          loading: true,
        });
        alert(error.message);
      });
  };
};
